import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const ImageAboutUs = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "aboutUs.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Img
      alt="About Us image"
      style={{
        maxHeight: "276px",
        width: "100%",
        height: "100%",
      }}
      fluid={data.placeholderImage.childImageSharp.fluid}
    />
  )
}

export default ImageAboutUs
