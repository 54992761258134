import React from "react"
import styled from "styled-components"
import { FaEnvelope } from "react-icons/fa"

import { FormSubmitBtn } from "../elements/buttons"

const SectionWrapper = styled.section`
  padding: 40px 0;
  @media (min-width: 768px) {
    padding: 60px 0;
  }
  @media (min-width: 992px) {
    padding: 75px 0;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0;
  margin-left: -15px;
  margin-right: -15px;
  @media (min-width: 768px) {
    padding: 60px 0;
  }
  @media (min-width: 992px) {
    padding: 75px 0;
  }
`

const ContactHeading = styled.h2`
  font-size: 26px;
  margin: 0;

  @media (min-width: 992px) {
    font-size: 32px;
  }
`

const ContentItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  padding: 0 15px;
  max-width: 100%;
  flex-basis: 100%;
  :first-child {
    align-self: flex-start;
  }

  .subtitle {
    font-size: 18px;
    line-height: 1.5;
    margin-top: 5px;
    @media (min-width: 768px) {
      font-size: 19px;
      margin-top: 15px;
    }
    @media (min-width: 992px) {
      font-size: 20px;
    }
  }

  @media (min-width: 768px) {
    max-width: 50%;
    flex-basis: 50%;
  }
`

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  span {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    a {
      text-decoration: none;
      color: inherit;
      cursor: pointer;
      transition: all 0.1s ease-in-out;
      :hover {
        text-decoration: underline;
      }
    }
  }
`

const FormWrapper = styled.div`
  padding: 40px 25px;
  background: #f8f7f2;
  @media (min-width: 480px) {
    padding: 60px 66.7px;
  }
  @media (min-width: 992px) {
    padding: 60px 100px;
  }
  .hide-element {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
  }
  form {
    label {
      display: block;
    }
    input,
    textarea {
      width: 100%;
      display: block;
      padding: 0.4rem 1rem;
      border-radius: 5px;
      border-color: transparent;
    }
    p {
      margin-bottom: 24px;
    }
  }
`

const ContactFormTemplate = () => {
  return (
    <SectionWrapper name="contact-form">
      <div className="container">
        <ContentWrapper>
          <ContentItem>
            <ContactHeading>Kontakt</ContactHeading>
            <p className="subtitle">Zapraszamy do kontaktu:</p>

            <ContactInfo
              className="text-box"
              itemScope
              itemType="http://schema.org/LocalBusiness"
            >
              <span>
                <FaEnvelope style={{ marginRight: "1rem" }} />
                <a href={`mailto:kontakt@jmsrail.com`} itemProp="email">
                  kontakt@jmsrail.com
                </a>
              </span>
              {/* <span>
                <FaPhone style={{ marginRight: "1rem" }} />
                <a href={`tel:+48531759555`} itemProp="telephone">
                  531 759 555
                </a>
              </span> */}
            </ContactInfo>
          </ContentItem>
          <ContentItem>
            <FormWrapper>
              <form
                name="contact"
                method="post"
                data-netlify="true"
                action="/success"
                netlify-honeypot="bot-field"
              >
                <input type="hidden" name="form-name" value="contact" />
                <p className="hide-element">
                  <label>
                    Nie wypełniaj tego pola jeśli jesteś człowiekiem
                    <input name="bot-field" />
                  </label>
                </p>
                <p>
                  <label htmlFor="name" className="hide-element">
                    Imie:
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Imie i nazwisko*"
                    required
                  />
                </p>
                <p>
                  <label htmlFor="phone" className="hide-element">
                    Numer telefonu:
                  </label>
                  <input
                    type="phone"
                    name="phone"
                    id="phone"
                    placeholder="Telefon"
                  />
                </p>
                <p>
                  <label htmlFor="email" className="hide-element">
                    Adres email:
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email*"
                    required
                  />
                </p>
                <p>
                  <label htmlFor="message" className="hide-element">
                    Wiadomość:
                  </label>
                  <textarea
                    name="message"
                    id="message"
                    placeholder="Napisz wiadomość"
                    rows="5"
                  />
                </p>
                <FormSubmitBtn type="submit">Wyślij</FormSubmitBtn>
              </form>
            </FormWrapper>
          </ContentItem>
        </ContentWrapper>
      </div>
    </SectionWrapper>
  )
}

export default ContactFormTemplate
