import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import AboutUsSectionTemplate from "../templates/aboutUsSectionTemplate"
import Motto from "../components/motto"
import WhyUsSectionTempalte from "../templates/whyUsSectionTempalte"
import BenefitsSectionTemplate from "../templates/benefitsSectionTemplate"
import OfferSectionTemplate from "../templates/offerSectionTemplate"
import CTASectionTemplate from "../templates/cTASectionTemplate"
import ContactFormTemplate from "../templates/contactFormTemplate"
import HeroSectionTemplate from "../templates/heroSectionTemplate"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Strona główna"
      keywords={[
        "jms",
        "jms rail systems",
        "rail",
        "systems",
        "systemy sterowania ruchem kolejowym",
        "sterowanie ruchem kolejowym",
        "systemy SRK",
        "SRK",
        "signalling",
        "signalling systems",
        "rail signaling",
      ]}
    />
    <HeroSectionTemplate />
    <AboutUsSectionTemplate />
    <Motto />
    <WhyUsSectionTempalte />
    <BenefitsSectionTemplate />
    <OfferSectionTemplate />
    <CTASectionTemplate />
    <ContactFormTemplate />
  </Layout>
)

export default IndexPage
