import React from "react"
import styled from "styled-components"

import { FiCheckCircle } from "react-icons/fi"

const SectionWrapper = styled.section`
  padding: 40px 0;
  @media (min-width: 768px) {
    padding: 60px 0;
  }
  @media (min-width: 992px) {
    padding: 75px 0;
  }

  header {
    margin-top: 30px;
    padding: 0 15px;
  }
`

const BenefitsHeading = styled.h2`
  font-size: 26px;
  margin: 0;
  text-align: center;
  flex-grow: 1;

  @media (min-width: 992px) {
    font-size: 32px;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
  margin-left: -15px;
  margin-right: -15px;

  @media (min-width: 992px) {
    margin-top: 20px;
  }
`

const ContentItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  padding: 0 15px;
  max-width: 100%;
  flex-basis: 100%;
  :last-child {
    margin-top: 0;
  }

  @media (min-width: 768px) {
    max-width: 50%;
    flex-basis: 50%;
    :last-child {
      margin-top: 30px;
    }
  }
`

const ListWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
`

const ListItem = styled.li`
  display: flex;
  margin-bottom: 16px;
`

const ListItemIcon = styled.span`
  display: flex;
  align-items: center;
  max-width: 20px;
  height: 24px;
  color: #776bff;
`
const ListItemContentBox = styled.div`
  margin-left: 10px;
  font-size: 18px;
  line-height: 27px;
  span {
    display: block;
  }
  @media (min-width: 768px) {
    font-size: 19px;
  }
  @media (min-width: 992px) {
    font-size: 20px;
  }
`

const BenefitsSectionTemplate = () => {
  return (
    <SectionWrapper>
      <div className="container">
        <article>
          <header>
            <BenefitsHeading>
              Co zyskujesz podejmując współpracę z nami?
            </BenefitsHeading>
          </header>
          <ContentWrapper>
            <ContentItem>
              <ListWrapper>
                <ListItem>
                  <ListItemIcon>
                    <FiCheckCircle />
                  </ListItemIcon>
                  <ListItemContentBox>
                    <span>
                      Zapewnienie maksymalnej ekonomiki procesu inwestycyjnego
                    </span>
                  </ListItemContentBox>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiCheckCircle />
                  </ListItemIcon>
                  <ListItemContentBox>
                    <span>
                      Bezpieczeństwo procesu inwestycyjnego dla wszystkich
                      zaangażowanych stron
                    </span>
                  </ListItemContentBox>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiCheckCircle />
                  </ListItemIcon>
                  <ListItemContentBox>
                    <span>Rzetelne przygotowanie oferty</span>
                  </ListItemContentBox>
                </ListItem>
              </ListWrapper>
            </ContentItem>
            <ContentItem>
              <ListWrapper>
                <ListItem>
                  <ListItemIcon>
                    <FiCheckCircle />
                  </ListItemIcon>
                  <ListItemContentBox>
                    <span>Opracowanie realnych rozwiązań technicznych</span>
                  </ListItemContentBox>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiCheckCircle />
                  </ListItemIcon>
                  <ListItemContentBox>
                    <span>
                      Sprawne planowanie inwestycji i realizacja kontraktu
                    </span>
                  </ListItemContentBox>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiCheckCircle />
                  </ListItemIcon>
                  <ListItemContentBox>
                    <span>
                      Minimalizację ryzyka i powstania zmian na etapie budowy
                    </span>
                  </ListItemContentBox>
                </ListItem>
              </ListWrapper>
            </ContentItem>
          </ContentWrapper>
        </article>
      </div>
    </SectionWrapper>
  )
}

export default BenefitsSectionTemplate
