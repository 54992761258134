import React from "react"
import styled from "styled-components"
import { useSpring, animated, config } from "react-spring"

const SloganWrapper = styled.div``

const Slogan = styled.h1`
  font-size: 48px;
  text-align: center;
  font-weight: 700;
  padding: 5px 15px;
  color: #f5f5f5;
  text-shadow: 1px 0.1px 1px #919191, 1px 0.2px 1px #919191,
    1px 0.3px 1px #919191, 1px 0.4px 1px #919191, 1px 0.5px 1px #919191,
    1px 0.6px 1px #919191, 1px 0.7px 1px #919191, 1px 0.8px 1px #919191,
    1px 0.9px 1px #919191, 1px 1px 1px #919191,
    1px 2px 6px rgba(16, 16, 16, 0.4), 1px 3px 10px rgba(16, 16, 16, 0.2),
    1px 4px 12px rgba(16, 16, 16, 0.2), 1px 5px 15px rgba(16, 16, 16, 0.4);
  @media (min-width: 480px) {
    font-size: 60px;
  }
  @media (min-width: 768px) {
    font-size: 84px;
  }
  @media (min-width: 992px) {
    font-size: 100px;
  }
`

const SloganSubtitle = styled.span`
  display: block;
  font-size: 30px;
  text-align: center;
  text-transform: uppercase;
  padding: 5px 15px;
  font-weight: 700;
  line-height: 1.5;
  color: #f5f5f5;
  text-shadow: 1px 0.1px 1px #919191, 1px 0.2px 1px #919191,
    1px 0.3px 1px #919191, 1px 0.4px 1px #919191, 1px 0.5px 1px #919191,
    1px 0.6px 1px #919191, 1px 0.7px 1px #919191, 1px 0.8px 1px #919191,
    1px 0.9px 1px #919191, 1px 1px 1px #919191,
    1px 2px 6px rgba(16, 16, 16, 0.4), 1px 3px 10px rgba(16, 16, 16, 0.2),
    1px 4px 12px rgba(16, 16, 16, 0.2), 1px 5px 15px rgba(16, 16, 16, 0.4);

  @media (min-width: 480px) {
    font-size: 37.5px;
  }
  @media (min-width: 768px) {
    font-size: 52.5px;
  }
  @media (min-width: 992px) {
    font-size: 62.5px;
  }
`

const HeroSlogan = ({ show }) => {
  const animateSlogan = useSpring({
    config: config.slow,
    opacity: show ? 1 : 0,
    transform: show ? "scale(1)" : "scale(0)",
  })

  const animateSubtitle = useSpring({
    config: config.slow,
    opacity: show ? 1 : 0,
    transform: show ? "scale(1)" : "scale(0)",
  })

  return (
    <SloganWrapper>
      <AnimatedSlogan style={animateSlogan}>JMS Rail Systems</AnimatedSlogan>
      <AnimatedSloganSubtitle style={animateSubtitle}>
        Tworzymy kolej z przyszłością
      </AnimatedSloganSubtitle>
    </SloganWrapper>
  )
}

const AnimatedSlogan = animated(Slogan)
const AnimatedSloganSubtitle = animated(SloganSubtitle)

export default HeroSlogan
