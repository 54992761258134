import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const ImageHero = ({ isLoaded }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "jms-hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Img
      onLoad={isLoaded}
      backgroundColor="#cfe2ea"
      alt="Hero image"
      style={{
        maxHeight: "80vh",
        minHeight: "60vh",
        width: "100%",
        height: "100%",
      }}
      fluid={data.placeholderImage.childImageSharp.fluid}
    />
  )
}

export default ImageHero
