import React from "react"
import styled from "styled-components"
import ImageAboutUs from "../components/imageAboutUs"
import { ButtonLink } from "../elements/buttons"

const SectionWrapper = styled.section`
  padding: 40px 0;
  @media (min-width: 768px) {
    padding: 60px 0;
  }
  @media (min-width: 992px) {
    padding: 75px 0;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 16px;
  margin-left: -15px;
  margin-right: -15px;
  @media (min-width: 992px) {
    padding: 0 40px;
  }
  @media (min-width: 1280px) {
    padding: 0;
  }
`

const ImageWrapper = styled.figure`
  max-width: 100%;
  margin-top: 30px;
  flex-basis: 100%;
  @media (min-width: 768px) {
    flex-basis: 50%;
    padding: 0 15px;
  }
`

const ArticleWrapper = styled.article`
  max-width: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 100%;
  @media (min-width: 768px) {
    flex-basis: 50%;
    padding: 0 15px;
  }

  p {
    font-size: 16px;
    margin-top: 5px;
    @media (min-width: 768px) {
      margin-top: 15px;
    }
  }

  h2 {
    margin: 0;
    font-size: 26px;
    @media (min-width: 768px) {
      font-size: 29px;
    }
    @media (min-width: 992px) {
      font-size: 32px;
    }
  }
`

const ButtonWrapper = styled.div`
  margin-top: 20px;
`

const AboutUsSectionTemplate = () => {
  return (
    <SectionWrapper name="about">
      <div className="container">
        <ContentWrapper>
          <ImageWrapper>
            <ImageAboutUs />
          </ImageWrapper>
          <ArticleWrapper>
            <header>
              <h2>O nas</h2>
            </header>
            <div>
              <p>
                Jesteśmy biurem projektowym, które specjalizuje się w
                projektowaniu systemów sterowania ruchem kolejowym i kontroli
                dla kolei konwencjonalnych, kolei dużych prędkości oraz metra...
              </p>
            </div>
            <ButtonWrapper>
              <ButtonLink to={"/about-us"}>Dowiedz się wiecej...</ButtonLink>
            </ButtonWrapper>
          </ArticleWrapper>
        </ContentWrapper>
      </div>
    </SectionWrapper>
  )
}

export default AboutUsSectionTemplate
