import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { PrimaryButton } from "../elements/buttons"
import { scrollToElements } from "../components/nav"

const CTASectionWrapper = styled.section`
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${({ src }) => src});
  background-attachment: inherit;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: transparent;
  @media (min-width: 768px) {
    background-attachment: fixed;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0;
  margin-top: -30px;
  margin-left: -15px;
  margin-right: -15px;
  @media (min-width: 768px) {
    padding: 60px 0;
  }
  @media (min-width: 992px) {
    padding: 75px 0;
  }
`

const ContentItem = styled.div`
  margin-top: 30px;
  padding: 0 15px;
  width: 100%;
  @media (min-width: 480px) {
    width: auto;
  }

  .subtitle {
    font-size: 18px;
    line-height: 1.5;
    margin-top: 5px;
    @media (min-width: 768px) {
      font-size: 19px;
      margin-top: 15px;
    }
    @media (min-width: 992px) {
      font-size: 20px;
    }
  }
`

const CTAHeading = styled.h2`
  font-size: 26px;
  margin: 0;
  flex-grow: 1;
  line-height: 1.4375;

  @media (min-width: 992px) {
    font-size: 32px;
  }
`

const CTASectionTemplate = () => {
  const data = useStaticQuery(graphql`
    query {
      ctaBgImage: file(relativePath: { eq: "jms-rail-systems-ctaBg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1480, quality: 60) {
            src
          }
        }
      }
    }
  `)

  return (
    <CTASectionWrapper
      srcWebp={data.ctaBgImage.childImageSharp.fluid.srcWebp}
      src={data.ctaBgImage.childImageSharp.fluid.src}
    >
      <div className="container">
        <ContentWrapper>
          <ContentItem>
            <article>
              <header>
                <CTAHeading>Jesteś zainteresowany współpracą?</CTAHeading>
              </header>
              <p className="subtitle">
                Skontaktuj się z nami! Odpowiemy na wszelkie pytania.
              </p>
            </article>
          </ContentItem>
          <ContentItem>
            <div>
              <PrimaryButton
                onClick={scrollToElements.bind(this, "contact-form")}
                name="contact us"
              >
                Skontaktuj się z nami
              </PrimaryButton>
            </div>
          </ContentItem>
        </ContentWrapper>
      </div>
    </CTASectionWrapper>
  )
}

export default CTASectionTemplate
