import React, { useState } from "react"
import styled from "styled-components"
import ImageHero from "../components/imageHero"
import HeroSlogan from "../components/heroSlogan"

const HeroWrapper = styled.section`
  position: relative;
  margin-top: 96.23px;
  @media (min-width: 768px) {
    margin-top: 0;
  }
`
const SloganBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const HeroSectionTemplate = () => {
  const [loaded, setLoaded] = useState(false)
  return (
    <HeroWrapper>
      <ImageHero isLoaded={() => setLoaded(true)} />
      <SloganBox>
        <HeroSlogan show={loaded} />
      </SloganBox>
    </HeroWrapper>
  )
}

export default HeroSectionTemplate
