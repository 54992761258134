import React from "react"
import styled from "styled-components"

import exp from "../images/icons/exp.svg"
import permissions from "../images/icons/permissions.svg"
import tech from "../images/icons/tech.svg"

const SectionWrapper = styled.section`
  padding: 40px 0;
  @media (min-width: 768px) {
    padding: 60px 0;
  }
  @media (min-width: 992px) {
    padding: 75px 0;
  }

  header {
    margin-top: 30px;
    padding: 0 15px;
  }
`

const WhyUsHeading = styled.h2`
  font-size: 26px;
  margin: 0;
  text-align: center;
  flex-grow: 1;

  @media (min-width: 992px) {
    font-size: 32px;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
  margin-left: -15px;
  margin-right: -15px;
  @media (min-width: 992px) {
    margin-top: 20px;
  }
`

const ContentItem = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
  padding: 0 15px;
  max-width: 100%;
  flex-basis: 100%;
  text-align: center;

  @media (min-width: 768px) {
    max-width: 33.33333%;
    flex-basis: 33.33333%;
  }
  h3 {
    font-size: 19px;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 0;
    line-height: 1.5;

    @media (min-width: 768px) {
      font-size: 21.5px;
    }
    @media (min-width: 992px) {
      font-size: 24px;
    }
  }
  p {
    margin-top: 15px;
    font-size: 16px;
    line-height: 1.5;
  }
`

const IconWrapper = styled.div`
  width: 100%;
  max-height: 75px;
  img {
    max-width: 75px;
    max-height: 75px;
    @media (min-width: 768px) {
      max-width: 100px;
      max-height: 100px;
    }
  }
  @media (min-width: 768px) {
    max-height: 100px;
  }
`

const WhyUsSectionTempalte = () => {
  return (
    <SectionWrapper>
      <div className="container">
        <article>
          <header>
            <WhyUsHeading>Dlaczego my?</WhyUsHeading>
          </header>
          <ContentWrapper>
            <ContentItem>
              <IconWrapper>
                <img src={exp} alt="Experience icon" aria-hidden="true" />
              </IconWrapper>
              <h3>Doświadczenie</h3>
              <p>
                Nasze rozwiązania opierają się na bogatym doświadczeniu zdobytym
                w Polsce jak i za granicą.
              </p>
            </ContentItem>
            <ContentItem>
              <IconWrapper>
                <img
                  src={permissions}
                  alt="Permissions icon"
                  aria-hidden="true"
                />
              </IconWrapper>
              <h3>Uprawnienia</h3>
              <p>
                Posiadamy nieograniczone uprawnienia budowlane do projektowania
                w zakresie urządzeń sterowania ruchem kolejowym w Polsce.
              </p>
            </ContentItem>
            <ContentItem>
              <IconWrapper>
                <img src={tech} alt="Technologies icon" aria-hidden="true" />
              </IconWrapper>
              <h3>Technologia</h3>
              <p>
                W naszej pracy wykorzystujemy najnowsze rozwiązania i
                technologie z zakresu inżynierii systemów i ich integracji.
              </p>
            </ContentItem>
          </ContentWrapper>
        </article>
      </div>
    </SectionWrapper>
  )
}

export default WhyUsSectionTempalte
