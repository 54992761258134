import React from "react"
import styled from "styled-components"

import study from "../images/icons/study.svg"
import reports from "../images/icons/reports.svg"
import build from "../images/icons/build.svg"
import srk from "../images/icons/srk.svg"
import expert from "../images/icons/expert.svg"
import coordination from "../images/icons/coordination.svg"

const SectionWrapper = styled.section`
  padding: 40px 0;
  @media (min-width: 768px) {
    padding: 60px 0;
  }
  @media (min-width: 992px) {
    padding: 75px 0;
  }

  header {
    margin-top: 30px;
    padding: 0 15px;
  }
`

const OfferHeading = styled.h2`
  font-size: 26px;
  margin: 0;
  text-align: center;
  flex-grow: 1;

  @media (min-width: 992px) {
    font-size: 32px;
  }
`

const Subtitle = styled.p`
  text-align: center;
  font-size: 18px;
  margin-top: 5px;
  line-height: 1.5;
  margin-left: -15px;
  margin-right: -15px;
  @media (min-width: 768px) {
    font-size: 19px;
    margin-top: 15px;
  }
  @media (min-width: 992px) {
    font-size: 20px;
  }
`

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 30px;

  @media (min-width: 992px) {
    margin-top: 40px;
  }
`

const ContentItem = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 15px;
  max-width: 100%;
  flex-basis: 100%;
  text-align: center;

  p {
    margin-top: 20px;
    font-size: 17px;
    line-height: 1.5;
    @media (min-width: 768px) {
      font-size: 17.5px;
    }
    @media (min-width: 992px) {
      font-size: 18px;
    }
  }
  @media (min-width: 768px) {
    max-width: 50%;
    flex-basis: 50%;
  }
  @media (min-width: 992px) {
    max-width: 33.33333%;
    flex-basis: 33.33333%;
  }
`

const IconWrapper = styled.div`
  width: 100%;
  max-height: 50px;
  img {
    max-width: 50px;
    max-height: 50px;
    @media (min-width: 768px) {
      max-width: 70px;
      max-height: 70px;
    }
  }
  @media (min-width: 768px) {
    max-height: 70px;
  }
`

const OfferSectionTemplate = () => {
  return (
    <SectionWrapper name="offer">
      <div className="container">
        <article>
          <header>
            <OfferHeading>Oferta</OfferHeading>
            <Subtitle>
              Tworzymy kompleksowe opracowania w zakresie sterowania ruchem
              kolejowym:
            </Subtitle>
          </header>
          <ContentWrapper>
            <ContentItem>
              <IconWrapper>
                <img src={study} alt="Study icon" aria-hidden="true" />
              </IconWrapper>
              <p>
                Studia wykonalności i koncepcje w tym analizy finansowo
                ekonomiczne
              </p>
            </ContentItem>
            <ContentItem>
              <IconWrapper>
                <img src={reports} alt="Reports icon" aria-hidden="true" />
              </IconWrapper>
              <p>Raporty i specyfikacje projektowe</p>
            </ContentItem>
            <ContentItem>
              <IconWrapper>
                <img src={build} alt="Build icon" aria-hidden="true" />
              </IconWrapper>
              <p>
                Opracowania dokumentacji projektowej projektów budowlanych
                wymagających pozwoleń na budowę
              </p>
            </ContentItem>
            <ContentItem>
              <IconWrapper>
                <img src={srk} alt="Srk icon" aria-hidden="true" />
              </IconWrapper>
              <p>
                Opracowania projektów wykonawczych w zakresie urządzeń SRK w tym
                projekty fazowania robót
              </p>
            </ContentItem>
            <ContentItem>
              <IconWrapper>
                <img src={expert} alt="Expertise icon" aria-hidden="true" />
              </IconWrapper>
              <p>Ekspertyzy i doradztwo techniczne</p>
            </ContentItem>
            <ContentItem>
              <IconWrapper>
                <img
                  src={coordination}
                  alt="Coordination icon"
                  aria-hidden="true"
                />
              </IconWrapper>
              <p>
                Współpraca w realizacji i koordynacji prac projektowych,
                budowlanych i nadzorze
              </p>
            </ContentItem>
          </ContentWrapper>
        </article>
      </div>
    </SectionWrapper>
  )
}

export default OfferSectionTemplate
